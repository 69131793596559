.App {
  padding: 2%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.tox-statusbar__branding{
  visibility: hidden !important
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.list{
  cursor: pointer;
  margin: 7px;
  list-style: none;
  color: rgb(233, 232, 232);
  background-color: rgba(3, 109, 49, 0.793);
  text-shadow: 0px 0px 1px #dedddd;
  padding: 10px;
  padding-left: 1.9%;
  padding-right: 1.9%;
  height: auto;
  border-radius: 7px;
  user-select: none;
}
.listWrapper {
  margin-top: 12px;
  margin-bottom: 6%;
  border-top: 1px solid rgb(176, 174, 174);
  border-bottom: 1px solid rgb(176, 174, 174);
  display: flex;
  overflow: auto;
}

.editor2 {
  margin-bottom: 10px;
}

@media screen and (min-width: 900px) {
  .editor {
    display: flex;
    width: 100%;
    
  }
  .editor2 {
    width: 100%;
    /* margin-left: 10px; */
    justify-content: space-evenly;
    /* background-color: #000000; */
  }
}

.key {
  color: #000000;
  text-shadow: 0px 0px 1.6px #000000;
  text-align:center;
}

.peer2 {
  color: #078b5f;
  text-shadow: 0px 0px 1.6px #135305;
  text-align:center;
}

.online {
  color: #268b07;
  font-size: small;
  /* text-shadow: 0px 0px 1.6px #135305; */
  text-align:center;
}

.offline {
  color: #aa0b0b;
  font-size: small;
  /* text-shadow: 0px 0px 1.6px #135305; */
  text-align:center;
}

.wrp {
  display: flex;
  justify-content: space-between;
}

.paper{
  text-align: 'center',
}

.buttonWrapper {
 margin: 100px;
}

.input2 {
  background-color: rgb(168, 255, 188);
  border: none;
  font-size: small;
  border-radius: 9px;
  padding: 5px;
  padding-left: 10.4px;
  padding-right: 10.4px;
  box-shadow: 0px 0px 2px 1px rgb(202, 236, 216)
}

.input {
  background-color: rgb(220, 220, 220);
  border: none;
  font-size: small;
  font-weight: 600;
  width: 90px;
  height: 20px;
  border-radius: 12px;
  padding: 5px;
  padding-left: 10.4px;
  padding-right: 10.4px;
  box-shadow: 0px 0px 1px 0px rgb(176, 175, 175)
}

.button {
  background-color: rgb(235, 168, 255);
  border: none;
  font-size: medium;
  user-select: none;
  border-radius: 9px;
  padding: 14px;
  padding-left: 30.4px;
  padding-right: 30.4px;
  box-shadow: 0px 0px 9px 2px rgb(144, 19, 182)
}

.button:active {
  background-color: rgb(213, 108, 245);
  
}

.filess {
  margin-left: 5px;
}

embed { 
  overflow:hidden !important;
  object-fit: contain;
  }

.drop {
  border: 1px dotted gray;
  height: 50px;
  width: 400px;
}

.zip {
  background-repeat: no-repeat;
  background-size: 80%;
  background-image: url('https://femiolayiwola.com/images/zipfile.png');
  width: 60px;
  height: 60px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.uploader {
  position: relative;
}

.loader {
  border: 6px solid #525598;
  border-radius: 50%;
  border-top: 6px solid rgba(226, 240, 232, 0.793);
  width: 20px;
  height: 20px;
  position: absolute;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  top: 27%;
  left: 8px;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}